/* You can add global styles to this file, and also import other style files */

@import url(fonts/materialicons.css);
@import url(fonts/roboto.css);

html,
body,
app-root {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


html {
    font-size: 14px;
}
